<template>
      <div class="d-flex page-wrapper flex-column" :class="{'onSidebarOpen' : sidebarVisible, 'report-all-page' : getRoute === 'reportall'}">
        <div class="tab d-flex gap-3 mb-4">
            <button class="tab--item font-700 font-nunito bg-transparent " :class="{'active' : getRoute === 'report' }" @click="$router.push('/report')">Laporan Hari ini</button>
            <button class="tab--item font-700 font-nunito bg-transparent" :class="{'active' : getRoute === 'reportall' }" @click="$router.push('/report-all')">Semua Laporan</button>
        </div>
        <report-daily v-if="getRoute === 'report' && shiftId" />
        <report-all v-else-if="getRoute === 'reportall'" />
         <div v-else
              class="
              d-flex 
              align-self-start
              mx-auto
              flex-column 
              flex-grow-1 
              px-4 pt-4 
              overflow-auto
              justify-content-center">
                <img src="@/assets/illustration/shift-illustration.svg" alt="">
                <span class="font-16 text-roboto font-400 text-center mt-2 ">Mulai shift terlebih dahulu <br> untuk melihat laporan harian</span>
            </div>
        <!-- <operational-cost v-if="getRoute === 'report'"/> -->
        <report-sidebar v-if="getRoute === 'reportall'" />
    </div>
</template>
<script>
import { mapState } from "vuex";
import ReportDaily from "../components/report/ReportDaily.vue";
import ReportAll from "../components/report/ReportAll.vue";
// import OperationalCost from "../components/report/popup/OperationalCost.vue";
import ReportSidebar from "../components/report/partials/ReportSidebar.vue";

export default {
    components : {
        ReportDaily,
        ReportAll,
        // OperationalCost,
        ReportSidebar
    },
    computed: {
        ...mapState('home', ['sidebarVisible']),
        ...mapState('shift', ['shiftId']),
        getRoute() {
            return this.$route.name.toLowerCase()
        }
    }
};
</script>
<style lang="scss" scoped>
.tab {
    border-bottom: 1px solid #CDCDCE;
	.tab--item {
		border: 0;
		padding-bottom: 7px;
		border-bottom: 5px solid transparent;
		&.active {
			border-bottom: 5px solid #006CB5;
			color: #006CB5;
		}
	}
}
.page-wrapper {
	padding: 16px;
	background: #f5f5f5;
	transition: margin 400ms ease-in-out;
	overflow-x: hidden;
	position: relative;
	&.onSidebarOpen {
		margin-left: 78px;
	}
	&.report-all-page {
		margin-right: 270px;
	}
}

  .tab {
      .tab--item {
          border: 0;
          padding-bottom: 7px;
          border-bottom: 5px solid transparent;
          &.active {
              border-bottom: 5px solid #006CB5;
              color: #006CB5;
          }
      }
  }
  .page-wrapper {
      padding: 24px;
    background: #F5F5F5;
    transition: margin 400ms ease-in-out;
    overflow-x: hidden;
    position: relative;
    &.onSidebarOpen {
      margin-left: 78px;
    }
    &.report-all-page {
        margin-right: 300px;
    }
  }
  
  .report-card {
    box-shadow: 0px 8px 16px 0px #89898926;
    border-radius: 8px;
    padding: 12px;
  }

.prospect {
	background-color: #dff9ed;
	padding: 4px;
	border-radius: 4px;
}

.table-container {
	background-color: white;
	padding: 24px;
	border-radius: 8px;
}

thead tr {
	border-bottom: 1px solid #e0e0e0;
}
</style>

